const state = {
  message: null,
  type: null,
  timeout: null
}

const mutations = {
  SET_MESSAGE(state, { message, type }) {
    state.message = message
    state.type = type
  },
  CLEAR_MESSAGE(state) {
    state.message = null
    state.type = null
  },
  SET_TIMEOUT(state, timeout) {
    state.timeout = timeout
  }
}

const actions = {
  showMessage({ commit, state }, { message, type = 'info', duration = 3000 }) {
    commit('SET_MESSAGE', { message, type })
    
    if (state.timeout) {
      clearTimeout(state.timeout)
    }
    
    const timeout = setTimeout(() => {
      commit('CLEAR_MESSAGE')
    }, duration)
    
    commit('SET_TIMEOUT', timeout)
  },
  clearMessage({ commit, state }) {
    if (state.timeout) {
      clearTimeout(state.timeout)
    }
    commit('CLEAR_MESSAGE')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}