import { api } from "@/api/api";

export default {
  namespaced: true,
  state: {
    user: null,
    isAuthenticated: false,
    authToken: localStorage.getItem("authToken") || null,
    availableVenues: [],
    currentVenueId: localStorage.getItem("currentVenueId") || null,
    currentAppRoleId: null,
    message: null,
    error: null,
  },
  mutations: {
    SET_USER(state, user) {
      console.info("Mutating SET_USER:", user);
      state.user = user;
      state.isAuthenticated = !!state.authToken;
    },
    SET_AUTH_TOKEN(state, token) {
      console.info("Mutating SET_AUTH_TOKEN:", token);
      state.authToken = token;
      if (token) {
        localStorage.setItem("authToken", token);
      } else {
        localStorage.removeItem("authToken");
      }
    },
    SET_AVAILABLE_VENUES(state, venues) {
      console.info("Mutating SET_AVAILABLE_VENUES:", venues);
      state.availableVenues = venues;
    },
    SET_CURRENT_VENUE(state, { venueId, appRoleId }) {
      console.info("Mutating SET_CURRENT_VENUE:", venueId, appRoleId);
      state.currentVenueId = venueId;
      state.currentAppRoleId = appRoleId;
      if (venueId) {
        localStorage.setItem("currentVenueId", venueId);
      } else {
        localStorage.removeItem("currentVenueId");
      }
    },
    SET_MESSAGE(state, message) {
      console.info("Mutating SET_MESSAGE:", message);
      state.message = message;
    },
    SET_ERROR(state, error) {
      console.info("Mutating SET_ERROR:", error);
      state.error = error;
    },
    CLEAR_MESSAGE(state) {
      console.info("Mutating CLEAR_MESSAGE");
      state.message = null;
    },
    CLEAR_ERROR(state) {
      console.info("Mutating CLEAR_ERROR");
      state.error = null;
    },
  },
  actions: {
    async login({ commit, dispatch }, credentials) {
      console.log("Action login with credentials:", credentials);
      try {
        const response = await api.post(`auth/login`, credentials);
        const { authToken } = response.data;
        if (authToken) {
          console.info("Received authToken:", authToken);
          commit("SET_AUTH_TOKEN", authToken);
          const userDetails = await dispatch("fetchUserDetails");

          if (
            userDetails._employee_venue_profiles &&
            userDetails._employee_venue_profiles.length === 1
          ) {
            const venue = userDetails._employee_venue_profiles[0];
            await dispatch("setEmployeeVenue", venue.venue_id);
          }
        } else {
          throw new Error("Authentication failed: No token received");
        }
        return response;
      } catch (error) {
        console.error("Login failed:", error);
        commit(
          "SET_ERROR",
          "Login failed. Please check your credentials and try again."
        );
      }
    },
    async fetchUserDetails({ commit }) {
      console.log("Action fetchUserDetails");
      try {
        const response = await api.get(`auth/me`);
        const userDetails = response.data;
        console.info("Fetched userDetails:", userDetails);
        commit("SET_USER", userDetails);
        if (userDetails._employee_venue_profiles) {
          const venues = userDetails._employee_venue_profiles.map(
            (profile) => ({
              id: profile.venue_id,
              roleId: profile.app_role_id,
              name: profile.name || `Venue ${profile.venue_id}`,
            })
          );
          console.info("Mapped available venues:", venues);
          commit("SET_AVAILABLE_VENUES", venues);
        }
        return userDetails;
      } catch (error) {
        console.error("Fetching user details failed:", error);
        commit("SET_ERROR", "Unable to fetch user details.");
      }
    },
    async setEmployeeVenue({ commit }, venueId) {
      console.log("Action setEmployeeVenue with venueId:", venueId);
      try {
        const response = await api.patch(`employee/set_venue`, {
          venue_id: venueId,
        });
        const { current_venue_id, current_app_role_id } = response.data;
        console.info(
          "Set employee venue to:",
          current_venue_id,
          current_app_role_id
        );
        commit("SET_CURRENT_VENUE", {
          venueId: current_venue_id,
          appRoleId: current_app_role_id,
        });
        return response.data;
      } catch (error) {
        console.error("Setting employee venue failed:", error);
        commit("SET_ERROR", "Failed to set venue.");
      }
    },
    logout({ commit }) {
      console.log("Action logout");
      commit("SET_USER", null);
      commit("SET_AUTH_TOKEN", null);
      commit("SET_AVAILABLE_VENUES", []);
      commit("SET_CURRENT_VENUE", { venueId: null, appRoleId: null });
      commit("SET_MESSAGE", "You have been logged out.");
    },
    checkAuth({ state, dispatch }) {
      console.log("Checking auth status");
      if (state.authToken) {
        return dispatch("fetchUserDetails");
      }
    },
    async requestPasswordReset({ commit }, email) {
      console.log("Action requestPasswordReset with email:", email);
      try {
        await api.get(`auth/magic-link`, {
          params: { email },
        });
        commit(
          "SET_MESSAGE",
          "If this email exists in our system, a password reset link was sent."
        );
      } catch (error) {
        console.error("Password reset request failed:", error);
        commit(
          "SET_MESSAGE",
          "If this email exists in our system, a password reset link was sent."
        );
      }
    },
    async checkMagicToken({ commit }, token) {
      console.log("Action checkMagicToken with token:", token);
      try {
        const response = await api.post(`auth/magic_login`, {
          magic_token: token,
        });
        const authToken = response.data;
        if (authToken) {
          console.info("Magic token verified, token:", authToken);
          commit("SET_AUTH_TOKEN", authToken);
          commit(
            "SET_MESSAGE",
            "Token verified successfully. You can now set a new password."
          );
        } else {
          throw new Error("Invalid magic token");
        }
      } catch (error) {
        console.error("Magic token verification failed:", error);
        commit("SET_ERROR", "Invalid or expired token.");
      }
    },
    async setNewPassword({ commit }, { newPassword }) {
      console.log("Action setNewPassword with newPassword.");
      try {
        await api.post(`auth/reset_password`, { new_password: newPassword });
        console.info("New password has been set");
        commit(
          "SET_MESSAGE",
          "Password changed successfully. You can now log in."
        );
        commit("SET_AUTH_TOKEN", null);
      } catch (error) {
        console.error("Setting new password failed:", error);
        commit("SET_ERROR", "Error changing password.");
      }
    },
    clearMessage({ commit }) {
      console.info("Clearing message");
      commit("CLEAR_MESSAGE");
    },
    clearError({ commit }) {
      console.info("Clearing error");
      commit("CLEAR_ERROR");
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.authToken,
    currentUser: (state) => state.user,
    availableVenues: (state) => state.availableVenues,
    currentVenueId: (state) => state.currentVenueId,
    currentAppRoleId: (state) => state.currentAppRoleId,
    authToken: (state) => state.authToken,
    hasSelectedVenue: (state) => !!state.currentVenueId,
    message: (state) => state.message,
    error: (state) => state.error,
  },
};
