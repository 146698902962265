import axios from "axios";
import store from "@/store";

const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_EMPLOYEES_ENDPOINT}`,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});

const publicApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_PUBLIC_ENDPOINT}`,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = store.getters["employees/authToken"];
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    console.info(`API Call: ${config.method.toUpperCase()} ${config.url}`);
    return config;
  },
  (error) => {
    console.error("API Request Error:", error);
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    console.info(
      `API Response: ${response.status} ${response.statusText}`,
      response.data
    );
    return response;
  },
  (error) => {
    console.error(
      "API Response Error:",
      error.response ? error.response.data : error.message
    );
    return Promise.reject(error);
  }
);

// Request interceptor
publicApi.interceptors.request.use(
  (config) => {
    console.info(
      `PUBLIC API Call: ${config.method.toUpperCase()} ${config.url}`
    );
    return config;
  },
  (error) => {
    console.error("PUBLIC API Request Error:", error);
    return Promise.reject(error);
  }
);

// Response interceptor
publicApi.interceptors.response.use(
  (response) => {
    console.info(
      `PUBLIC API Response: ${response.status} ${response.statusText}`,
      response.data
    );
    return response;
  },
  (error) => {
    console.error(
      "PUBLIC API Response Error:",
      error.response ? error.response.data : error.message
    );
    return Promise.reject(error);
  }
);
export { api, publicApi };
