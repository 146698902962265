export function loadView(view) {
  return () => import(`@/views/${view}.vue`)
}

export function generateRoutes(routeConfigs) {
  return routeConfigs.map(config => ({
    ...config,
    component: loadView(config.component),
    children: config.children ? generateRoutes(config.children) : undefined
  }))
}