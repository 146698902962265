<template>
  <transition name="fade">
    <div
      v-if="message"
      class="fixed bottom-4 right-4 z-50 p-4 rounded-md flex items-center"
      :class="typeClasses"
    >
      <component :is="icon" class="w-6 h-6 mr-2" />
      <span>{{ message }}</span>
    </div>
  </transition>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/vue/24/solid'

export default {
  name: "CloudMessage",
  components: {
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon
  },
  setup() {
    const store = useStore();

    const message = computed(() => store.state.cloudMessage.message);
    const type = computed(() => store.state.cloudMessage.type);

    const typeClasses = computed(() => {
      switch (type.value) {
        case "success":
          return "bg-green-400 text-white";
        case "error":
          return "bg-red-400 text-white";
        case "warning":
          return "bg-yellow-400 text-white";
        default:
          return "bg-blue-400 text-white";
      }
    });

    const icon = computed(() => {
      switch (type.value) {
        case "success":
          return CheckCircleIcon;
        case "error":
          return ExclamationCircleIcon;
        case "warning":
          return ExclamationTriangleIcon;
        default:
          return InformationCircleIcon;
      }
    });

    return {
      message,
      typeClasses,
      icon,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
