import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import { generateRoutes } from "./auto-import";
import { getAllClients } from "@/api/clients";

const routeConfigs = [
  {
    path: "/employees/login",
    name: "EmployeeLogin",
    component: "employees/LoginView",
    props: (route) => ({ magicToken: route.query.magicToken }),
  },
  {
    path: "/employees/logout",
    name: "EmployeeLogout",
    component: "employees/LogoutView",
  },
  {
    path: "/",
    name: "Home",
    component: "calendar/Calendar",
    meta: { requiresAuth: true, requiresVenue: true },
  },
  {
    path: "/reservationList",
    name: "ReservationList",
    component: "HomeView",
    meta: { requiresAuth: true, requiresVenue: true },
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: "calendar/Calendar",
    meta: { requiresAuth: true, requiresVenue: true },
  },
  {
    path: "/settings/employees",
    name: "Employees",
    component: "settings/employees",
    meta: { requiresAuth: true, requiresVenue: true },
  },
  {
    path: "/select-venue",
    name: "SelectVenue",
    component: "employees/SelectVenueView",
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "VenueSettings",
    component: "venueSettings/home",
    meta: { requiresAuth: true, requiresVenue: true },
    children: [
      {
        path: "basic",
        name: "BasicSettings",
        component: "venueSettings/venue/BasicSettings",
      },
      {
        path: "tags",
        name: "TagsSettings",
        component: "venueSettings/tags/TagsSettings",
      },
      {
        path: "sports-facilities",
        name: "SportsFacilitiesSettings",
        component: "venueSettings/sportObjects/SportsFacilitiesSettings",
      },
      {
        path: "seasons",
        name: "SeasonsSettings",
        component: "venueSettings/seasons/SeasonsSettings",
      },
      {
        path: "pricing",
        name: "PricingSettings",
        component: "venueSettings/pricing/PricingSettings",
      },
      {
        path: "pricing/:id",
        name: "SinglePriceList",
        component: "venueSettings/pricing/SinglePriceList",
        props: true,
      },
      {
        path: "pricing/:id/edit/:priceListId",
        name: "SinglePriceEdit",
        component: "venueSettings/pricing/SinglePriceEdit",
        props: true,
      },
      {
        path: "carnets",
        name: "CarnetSettings",
        component: "venueSettings/carnets/CarnetSettings",
      },
      {
        path: "/carnets/:id/edit",
        name: "EditCarnet",
        component: "venueSettings/carnets/EditCarnet",
      },
    ],
  },
  {
    path: "/clients",
    name: "Clients",
    component: "clients/clients",
    meta: { requiresAuth: true, requiresVenue: true },
    children: [
      {
        path: "info/:id",
        name: "ClientInfo",
        component: "clients/info",
        props: true,
      },
      {
        path: "reservations/:id",
        name: "ClientReservations",
        component: "clients/reservations",
        props: true,
      },
      {
        path: "reservations-recurring/:id",
        name: "ClientRecurringReservations",
        component: "clients/reservationsReccuring",
        props: true,
      },
      {
        path: "carnets/:id",
        name: "ClientCarnets",
        component: "clients/carnets",
        props: true,
      },
      {
        path: "trainer-profile/:id",
        name: "TrainerProfile",
        component: "clients/trainerProfile",
        props: true,
      },
    ],
  },
];

const routes = generateRoutes(routeConfigs);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters["employees/isAuthenticated"];
  const hasSelectedVenue = store.getters["employees/hasSelectedVenue"];

  console.log(
    `Route check: isAuthenticated=${isAuthenticated}, hasSelectedVenue=${hasSelectedVenue}`
  );

  if (to.name === "EmployeeLogin" && to.query.magicToken) {
    next();
  } else if (to.meta.requiresAuth && !isAuthenticated) {
    next("/employees/login");
  } else if (to.meta.requiresVenue && isAuthenticated && !hasSelectedVenue) {
    if (to.name !== "SelectVenue") {
      next("/select-venue");
    } else {
      next();
    }
  } else if (to.path === "/employees/login" && isAuthenticated) {
    if (hasSelectedVenue) {
      next("/");
    } else {
      next("/select-venue");
    }
  } else if (to.name === "ClientInfo" && to.params.id === "first") {
    try {
      const clients = await getAllClients();
      if (clients.length > 0) {
        next({ name: "ClientInfo", params: { id: clients[0].id.toString() } });
      } else {
        // Handle the case when there are no clients
        next("/clients");
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      next("/clients");
    }
  } else {
    next();
  }
});

export default router;
