<template>
  <div
    v-show="isLoading"
    class="top-loader"
    :style="{ width: `${progress}%` }"
  ></div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "TopLoader",
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const progress = ref(0);
    let interval;

    const startLoading = () => {
      isLoading.value = true;
      progress.value = 0;
      interval = setInterval(() => {
        if (progress.value < 90) {
          progress.value += Math.random() * 10;
        }
      }, 200);
    };

    const stopLoading = () => {
      clearInterval(interval);
      progress.value = 100;
      setTimeout(() => {
        isLoading.value = false;
        progress.value = 0;
      }, 200);
    };

    onMounted(() => {
      store.watch(
        (state) => state.app.isLoading,
        (newValue) => {
          if (newValue) {
            startLoading();
          } else {
            stopLoading();
          }
        }
      );
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    return {
      isLoading,
      progress,
    };
  },
};
</script>

<style scoped>
.top-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 2px;
  background-color: var(--color-dynamic-orange);
  transition: width 0.2s ease-in-out;
  z-index: 9999;
}
</style>
