import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/main.css";
import "./assets/playmore.css";

const app = createApp(App);

app.use(store);
app.use(router);

// Initialize dark mode
const isDarkMode = localStorage.getItem("darkMode") === "true";
if (isDarkMode) {
  document.documentElement.classList.add("dark");
}

store.dispatch("employees/checkAuth").then(() => {
  app.mount("#app");
});
